import React from 'react';

const About = () => {
    return (
        <div name='about' className='w-full my-32'>
            <div className='max-w-[1240px] mx-auto'>
                <div className='text-center'>
                    <p className='text-5xl font-bold'>Trusted by customers across the world</p>
                    <h2 className='text-3xl py-6 text-gray-500'>Packaging machine controls for new and existing applications at OEM price points</h2>
                </div>

                <div className='grid md:grid-cols-3 gap-1 px-2 text-center'>
                    <div className='border py-8 rounded-xl shadow-xl'>
                        <p className='font-bold text-3xl text-sky-600'>100%</p>
                        <h3 className='font-bold text-2xl'>Customizable</h3>
                    </div>
                    <div className='border py-8 rounded-xl shadow-xl'>
                        <h3 className='font-bold text-3xl text-sky-600'>Installation</h3>
                        <h3 className='font-bold text-2xl'>Services Available</h3>
                    </div>
                    <div className='border py-8 rounded-xl shadow-xl'>
                        <p className='font-bold text-3xl text-sky-600'>100%</p>
                        <h3 className='font-bold text-2xl'>UL Listed Components</h3>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default About;
