import React, { useState } from 'react';
import Navbar from './Navbar';
import Hero from './Hero';
import About from './About';
import Support from './Support';
import Solutions from './Solutions';
import Footer from './Footer';
import Estimate from './Estimate';

const Home = () => {
    const[showModal, setShowModal] = useState(false);

    const toggleModal = () => setShowModal(!showModal);

    return (
        <div className="bg-white">
            <Navbar toggleModal={toggleModal} />
            <Hero />
            <About />
            <Support />
            <Solutions />
            <Footer toggleModal={toggleModal}/>

            <Estimate showModal={showModal} toggleModal={toggleModal} /> 
      </div>
    );
};

export default Home;