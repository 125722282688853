import React, { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { Flip, toast } from 'react-toastify';

const SecretPage = () => {
    const { logout } = useAuth0();
    
    const [formData, setFormData] = useState({
        subject: '', 
        message: ''
      });

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name] : e.target.value,
        });
    };

    const resetForm = () => {
        setFormData({
          subject: '', 
          message: ''
        });
      };

    const handleSubmit = (e) => {
        e.preventDefault();
        
        const mailObj = {
            subject: `${formData.subject}`,
            html: `${formData.message}\n\n\n<p><a href="https://www.jandjind.com/unsubscribe">Unsubscribe here</a></p>`
        }
        
        axios.post('https://j-and-j-gcp-api-tbybzsrngq-uc.a.run.app/06fa905d7f2aaced6dc72e9511c71a2a51e8aead/send', { mailObj})
          .then(() => {
            toast.success('Newsletter sent successfully!', {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              transition: Flip
              });
          })
          .catch((error) => {
            console.error('Error sending message', error);
          });
          resetForm();
      };

    const handleLogout = () => {
      // Perform the logout operation
      logout({ returnTo: window.location.origin });

      // Clear all caches
      if ('caches' in window) {
          caches.keys().then(function(names) {
              Promise.all(names.map(name => caches.delete(name))).then(() => {
                  // Ensure the cache is cleared before reloading
                  window.location.reload();
              });
          });
      } else {
          // If caching is not supported, just reload the page
          window.location.reload();
      }
    };

    return (
        <div className='flex items-center justify-center min-h-screen bg-gray-100'>
          <div className='m-4 w-full max-w-md bg-gray-200 border border-black p-6 rounded-lg shadow-md'>
              <h1 className='mb-4'>You are logged in!</h1>
              <button className='w-full focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center' 
                onClick={handleLogout}>
                  Log Out
              </button>
              <h2 className='m-4'>Send a newsletter by filling out the form below.</h2>
              {/* CEO's special email sending functionality can be added here */}
              <form className="space-y-4" onSubmit={handleSubmit}>
                
                <div>
                  <input
                    type="text"
                    name="subject"
                    id="subject"
                    className="mt-4 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                    placeholder="Email subject"
                    value={formData.subject}
                    onChange={handleChange}
                    required
                  />
                </div>

                <div>
                  <textarea
                    name="message"
                    id="message"
                    rows="8"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                    placeholder="Your message here"
                    value={formData.message}
                    onChange={handleChange}
                    required
                  />
                </div>

                <button type="submit" className="w-full focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">Send Message</button>

              </form>
          </div>
        </div>
    );
}

export default SecretPage;
