import panel from '../assets/overflow-filler.JPG';

import React, { useState } from 'react';
import { CheckIcon } from '@heroicons/react/outline';
import Navbar2 from './Navbar2';
import Footer from './Footer';
import Estimate from './Estimate';

const OverflowFillerControls = () => {
    const[showModal, setShowModal] = useState(false);

    const toggleModal = () => setShowModal(!showModal);

    return (
        <>
            <Navbar2 toggleModal={toggleModal} />
            
            <div className='w-full pt-6 flex flex-col justify-between'>
                <div className='grid md:grid-cols-2 p-1 max-w-[1240px] m-auto'>
                    <div className='flex justify-center items-center px-8 py-8'>
                        <img className='md:rotate-90 mt-12 md:mt-0 max-w-auto md:max-w-md h-auto rounded-xl shadow-2xl' src={panel} alt="overflow filler" />
                    </div>
                    <div className='flex flex-col justify-center md:items-start w-full px-2 py-8'>
                        <h1 className='py-3 text-5xl md:text-7xl font-bold'>Overflow Filler Controls</h1>
                        <h2 className='text-2xl'>Customizable controls for overflow filler applications</h2>
                        <p className='text-4xl font-bold py-4'><p className='text-sm font-bold mt-4'>Starting at</p>$6,500</p>
                        <button className='py-3 px-6 sm:w-[60%] my-4' onClick={toggleModal}>Contact Us</button>
                    </div>
                </div>

                <div className='text-2xl grid md:grid-cols-2 pl-4 gap-4'>
                        <h3 className='flex items-center py-4 text-md md:text-xl'><CheckIcon className='w-8 h-8 mr-5 flex-shrink-0 text-green-600' />Allen Bradley MicroLogix 1400 PLC with all required expansion modules</h3>
                        <h3 className='flex items-center py-4 text-md md:text-xl'><CheckIcon className='w-8 h-8 mr-5 flex-shrink-0 text-green-600' />7.0” Dual Ethernet color touch advanced Maple Systems HMI</h3>
                        <h3 className='flex items-center py-4 text-md md:text-xl'><CheckIcon className='w-8 h-8 mr-5 flex-shrink-0 text-green-600' />SMC manifold mount 5 port 2 position 24VDC air solenoid valves</h3>
                        <h3 className='flex items-center py-4 text-md md:text-xl'><CheckIcon className='w-8 h-8 mr-5 flex-shrink-0 text-green-600' />Uh3 to four programmable fill speeds and times to control fill levels</h3>
                        <h3 className='flex items-center py-4 text-md md:text-xl'><CheckIcon className='w-8 h-8 mr-5 flex-shrink-0 text-green-600' />Fill pump controlled via Lenze AC Drive</h3>
                        <h3 className='flex items-center py-4 text-md md:text-xl'><CheckIcon className='w-8 h-8 mr-5 flex-shrink-0 text-green-600' />Capable of pin, starwheel and remote signal container indexing</h3>
                        <h3 className='flex items-center py-4 text-md md:text-xl'><CheckIcon className='w-8 h-8 mr-5 flex-shrink-0 text-green-600' />50 user friendly product stored recipes</h3>
                        <h3 className='flex items-center py-4 text-md md:text-xl'><CheckIcon className='w-8 h-8 mr-5 flex-shrink-0 text-green-600' />Includes infeed count and two line backup zone status inputs</h3>
                        <h3 className='flex items-center py-4 text-md md:text-xl'><CheckIcon className='w-8 h-8 mr-5 flex-shrink-0 text-green-600' />Integrated mechanical product float system with high and low product tank limits</h3>
                        <h3 className='flex items-center py-4 text-md md:text-xl'><CheckIcon className='w-8 h-8 mr-5 flex-shrink-0 text-green-600' />ANSI gray mild painted steel electrical enclosure</h3>
                        <h3 className='flex items-center py-4 text-md md:text-xl'><CheckIcon className='w-8 h-8 mr-5 flex-shrink-0 text-green-600' />Lock out tag out integrated main power disconnect</h3>
                        <h3 className='flex items-center py-4 text-md md:text-xl'><CheckIcon className='w-8 h-8 mr-5 flex-shrink-0 text-green-600' />Supports drip tray, container locators and fill head dive functionality </h3>
                        <h3 className='flex items-center py-4 text-md md:text-xl'><CheckIcon className='w-8 h-8 mr-5 flex-shrink-0 text-green-600' />AC or DC conveyor control</h3>	
                </div>
            </div>

            <Footer toggleModal={toggleModal} />

            <Estimate showModal={showModal} toggleModal={toggleModal} /> 
        </>
    );
};

export default OverflowFillerControls;