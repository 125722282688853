import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import ScrollToTop from './components/ScrollToTop';
import Home from './components/Home';
import LoginPage from './components/LoginPage';
import SecretPage from './components/SecretPage';
import Unsubscribe from './components/Unsubscribe';
import GravityFillerControls from './components/GravityFillerControls';
import PumpFillerControls from './components/PumpFillerControls';
import OverflowFillerControls from './components/OverflowFillerControls';
import PistonFillerControls from './components/PistonFillerControls';
import SpindleCapperControls from './components/SpindleCapperControls';
import OtherSolutions from './components/OtherSolutions';
import NotFound from './components/NotFound';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ProtectedRoute = ({ element }) => {
  const { isAuthenticated } = useAuth0();
  return isAuthenticated ? element : <Navigate to="/" />;
};

function App() {
  const { isLoading } = useAuth0();

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/login' element={<LoginPage />} />
        {/* SHA1 hash named 'stephen' */}
        <Route path='/06fa905d7f2aaced6dc72e9511c71a2a51e8aead' element={<ProtectedRoute element={<SecretPage />} />} />
        <Route path='/unsubscribe' element={<Unsubscribe />} />
        <Route path='/gravity-filler-controls' element={<GravityFillerControls />} />
        <Route path='/pump-filler-controls' element={<PumpFillerControls />} />
        <Route path='/overflow-filler-controls' element={<OverflowFillerControls />} />
        <Route path='/piston-filler-controls' element={<PistonFillerControls />} />
        <Route path='/spindle-capper-controls' element={<SpindleCapperControls />} />
        <Route path='/other-solutions' element={<OtherSolutions />} />
        <Route path='*' element={<NotFound />} />
      </Routes>
      <ToastContainer />
    </Router>
  );
}

export default App;