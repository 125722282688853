import logo from "../assets/logo.png";

import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { MenuIcon, XIcon } from '@heroicons/react/outline';

const Navbar = ({ toggleModal }) => {
    const [nav, setNav] = useState(false);
    const handleClick = () => setNav(!nav);
    const handleClose = () => setNav(!nav);

    return (
        <div className='w-screen h-[80px] z-10 bg-zinc-200 fixed drop-shadow-lg'>
            <div className='px-2 flex justify-between items-center w-full h-full'>
                <div className='flex items-center'>
                    <RouterLink to="/" className="flex items-center font-bold">
                        <img className="object-scale-down h-12 w-12" src={logo} alt="J & J Industrial Service" /><h1 className="text-xl">J & J Industrial Service</h1>
                    </RouterLink>
                    <ul className='hidden md:flex'>
                        <li><RouterLink className="hover:text-sky-600" to="/">Home</RouterLink></li>
                    </ul>
                </div>
                <div className='hidden md:flex pr-4'>
                    <button className='px-5 py-2' onClick={toggleModal}>Free Estimate</button>
                </div>
                <div className='md:hidden mr-4' onClick={handleClick}>
                    {!nav ? <MenuIcon className='w-5' /> : <XIcon className='w-5' />}

                </div>
            </div>
            <ul className={!nav ? 'hidden' : 'absolute bg-zinc-200 w-full px-8'}>
                <li className='border-b-2 border-zinc-300 w-full'><RouterLink onClick={handleClose} to="/">Home</RouterLink></li>
                <div className='flex flex-col my-4'>
                    <button className='px-8 py-3' onClick={toggleModal}>Free Estimate</button>
                </div>
            </ul>
        </div>
    );
};

export default Navbar;