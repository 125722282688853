import React from 'react';

const NotFound = () => {
  return (
    <div style={{ textAlign: 'center', marginTop: '50px' }}>
      <h1 className='text-8xl text-sky-600 font-bold p-4'>404</h1>
      <p className='text-xl font-bold'>Sorry, the page you are looking for does not exist</p>
      <button className='p-2 mt-5'><a href="/">Go back to the homepage</a></button>
    </div>
  );
};

export default NotFound;