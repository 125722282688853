import React from 'react';

import {
    FaFacebook
} from 'react-icons/fa'

import { Link } from 'react-router-dom';

function CopyrightYear() {
    return  <p className='py-4' id="copyright-year">Copyright © {new Date().getFullYear()} J & J Industrial Service | All Rights Reserved</p>;
}

const Footer = ({ toggleModal }) => {
    return (
        <div name="footer" className='w-full mt-24 bg-slate-900 text-gray-300 py-y px-2'>
            <div className='max-w-[1240px] mx-auto grid grid-cols-2 md:grid-cols-4 border-b-2 border-gray-600 py-8'>
                <div>
                    <h6 className='font-bold uppercase pt-2'>Solutions</h6>
                    <ul>
                        <li className='py-1'><Link to="/gravity-filler-controls">Gravity Filler Controls</Link></li>
                        <li className='py-1'><Link to="/pump-filler-controls">Pump Filler Controls</Link></li>
                        <li className='py-1'><Link to="/overflow-filler-controls">Overflow Filler Controls</Link></li>
                        <li className='py-1'><Link to="/piston-filler-controls">Piston Filler Controls</Link></li>
                        <li className='py-1'><Link to="/spindle-capper-controls">Spindle Capper Controls</Link></li>
                        <li className='py-1'><Link to="/other-solutions">Other Solutions</Link></li>
                    </ul>
                </div>
                <div>
                    <h6 className='font-bold uppercase pt-2'>Contact</h6>
                    <ul>
                        <li className='py-1'><a href="tel:219-362-4973">219-362-4973</a></li>
                        <li className='py-1'><a href="mailto:stephen@jandjind.com">stephen@jandjind.com</a></li>
                        <li className='py-1'><a href="https://www.google.com/maps/place/J+%26+J+Industrial+Service/@41.6294123,-86.6804604,19.67z/data=!4m15!1m8!3m7!1s0x881113e094ec9e95:0xf3a67cce3408cd0d!2s2204+E+Lincolnway,+La+Porte,+IN+46350!3b1!8m2!3d41.6296841!4d-86.6800667!16s%2Fg%2F11c22l43_g!3m5!1s0x881113a0dcb8898b:0x5cdf5516c2a3be01!8m2!3d41.629715!4d-86.6800335!16s%2Fg%2F11pzygcfbv?entry=ttu" target="_blank" rel="noopener noreferrer">2204 E Lincolnway, La Porte, IN</a></li>
                    </ul>
                </div>
                <div className='col-span-2 pt-8 md:pt-2'>
                    <p className='font-bold uppercase'>Click below for a free estimate today!</p>
                    <button className='w-[22rem] p-2 mt-3 mb-4' onClick={toggleModal}>Free Estimate</button>
                </div>
            </div>

            <div className='flex flex-col max-w-[1240px] px-2 py-4 mx-auto justify-center sm:flex-row text-center text-gray-300'>
                <CopyrightYear />
                <div className='flex sm:w-[300px] pt-4 text-2xl justify-center'>
                    <a href="https://www.facebook.com/stephenjandjind" target="_blank" rel="noopener noreferrer"><FaFacebook /></a>
                </div>
            </div>
        </div>
    );
};

export default Footer;