import React, { useState } from 'react';
import axios from 'axios';
import { Flip, toast } from 'react-toastify';

const Unsubscribe = () => {
    
    const [formData, setFormData] = useState({
        email: ''
      });

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name] : e.target.value,
        });
    };

    const resetForm = () => {
        setFormData({
          email: ''
        });
      };

    const handleSubmit = (e) => {
        e.preventDefault();
        
        const emailObj = {
            email: `${formData.email}`
        }
        
        axios.post('https://j-and-j-gcp-api-tbybzsrngq-uc.a.run.app/unsubscribe/send', { emailObj })
          .then(() => {
            toast.success('Unsubscribed successfully.', {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              transition: Flip
              });
          })
          .catch((error) => {
            console.error('Error unsubscribing', error);
          });
          resetForm();
      };

    return (
        <div className='flex items-center justify-center min-h-screen bg-gray-100'>    
            <div className='m-4 w-full max-w-md bg-gray-200 border border-black p-6 rounded-lg shadow-md'>
                <h1>We are sad to see you go!</h1>

                <form className="space-y-4" onSubmit={handleSubmit}>
                
                <div>
                    <input
                    type="email"
                    name="email"
                    id="email"
                    className="mt-4 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                    placeholder="name@company.com"
                    value={formData.email}
                    onChange={handleChange}
                    required
                    />
                </div>

                <button type="submit" className="w-full focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">Unsubscribe</button>

                </form>
            </div>
        </div>
    );
}

export default Unsubscribe;
