import panel from '../assets/spindle-capper.JPG';

import React, { useState } from 'react';
import { CheckIcon } from '@heroicons/react/outline';
import Navbar2 from './Navbar2';
import Footer from './Footer';
import Estimate from './Estimate';

const SpindleCapperControls = () => {
    const[showModal, setShowModal] = useState(false);

    const toggleModal = () => setShowModal(!showModal);

    return (
        <>
            <Navbar2 toggleModal={toggleModal} />
            
            <div className='w-full pt-20 flex flex-col justify-between'>
                <div className='grid md:grid-cols-2 p-1 max-w-[1240px] m-auto'>
                    <div className='flex justify-center items-center px-8 py-8'>
                        <img className='h-auto rounded-xl shadow-2xl' src={panel} alt="spindle capper" />
                    </div>
                    <div className='flex flex-col justify-center md:items-start w-full px-2 py-8'>
                        <h1 className='py-3 text-5xl md:text-7xl font-bold'>Spindle Capper Controls</h1>
                        <h2 className='text-2xl'>Customizable controls for spindle capper applications</h2>
                        <p className='text-4xl font-bold py-4'><p className='text-sm font-bold mt-4'>Starting at</p>$3,800</p>
                        <button className='py-3 px-6 sm:w-[60%] my-4' onClick={toggleModal}>Contact Us</button>
                    </div>
                </div>

                <div className='text-2xl mt-8 grid md:grid-cols-2 pl-4 gap-4'>
                        <h3 className='flex items-center py-4 text-md md:text-xl'><CheckIcon className='w-8 h-8 mr-5 flex-shrink-0 text-green-600' />Quoted price for three station capper non-reversing controls</h3>
                        <h3 className='flex items-center py-4 text-md md:text-xl'><CheckIcon className='w-8 h-8 mr-5 flex-shrink-0 text-green-600' />Independent DC drive speed to be controlled via potentiometers</h3>
                        <h3 className='flex items-center py-4 text-md md:text-xl'><CheckIcon className='w-8 h-8 mr-5 flex-shrink-0 text-green-600' />Two position IP65 selector switches for drive OFF/ON selection</h3>
                        <h3 className='flex items-center py-4 text-md md:text-xl'><CheckIcon className='w-8 h-8 mr-5 flex-shrink-0 text-green-600' />ANSI gray mild painted steel electrical enclosure</h3>
                        <h3 className='flex items-center py-4 text-md md:text-xl'><CheckIcon className='w-8 h-8 mr-5 flex-shrink-0 text-green-600' />Lock out tag out integrated main power disconnect</h3>
                        <h3 className='flex items-center py-4 text-md md:text-xl'><CheckIcon className='w-8 h-8 mr-5 flex-shrink-0 text-green-600' />AC or DC conveyor control</h3>
                </div>
            </div>

            <Footer toggleModal={toggleModal} />

            <Estimate showModal={showModal} toggleModal={toggleModal} /> 
        </>
    );
};

export default SpindleCapperControls;