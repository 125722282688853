import logo from "../assets/logo.png";

import React, { useState } from 'react';
import { Link as ScrollLink } from 'react-scroll';
import { MenuIcon, XIcon } from '@heroicons/react/outline';

const Navbar = ({ toggleModal }) => {
    const [nav, setNav] = useState(false);
    const handleClick = () => setNav(!nav);
    const handleClose = () => setNav(!nav);

    return (
        <div className='w-screen h-[80px] z-10 bg-zinc-200 fixed drop-shadow-lg'>
            <div className='px-2 flex justify-between items-center w-full h-full'>
                <div className='flex items-center'>
                    <ScrollLink to="hero" smooth={true} offset={0} duration={500} className="flex items-center font-bold">
                        <img className="object-scale-down h-12 w-12" src={logo} alt="J & J Industrial Service" /><h1 className="text-xl">J & J Industrial Service</h1>
                    </ScrollLink>
                    <ul className='hidden md:flex'>
                        <li><ScrollLink className="hover:text-sky-600" to="hero" smooth={true} offset={0} duration={500}>Home</ScrollLink></li>
                        <li><ScrollLink className="hover:text-sky-600" to="about" smooth={true} offset={-150} duration={500}>About</ScrollLink></li>
                        <li><ScrollLink className="hover:text-sky-600" to="support" smooth={true} offset={-25} duration={500}>Support</ScrollLink></li>
                        <li><ScrollLink className="hover:text-sky-600" to="solutions" smooth={true} offset={-25} duration={500}>Solutions</ScrollLink></li>
                        <li><ScrollLink className="hover:text-sky-600" to="footer" smooth={true} offset={0} duration={500}>Contact</ScrollLink></li>
                    </ul>
                </div>
                <div className='hidden md:flex pr-4'>
                    <button className='px-5 py-2' onClick={toggleModal}>Free Estimate</button>
                </div>
                <div className='md:hidden mr-4' onClick={handleClick}>
                    {!nav ? <MenuIcon className='w-5' /> : <XIcon className='w-5' />}

                </div>
            </div>
            <ul className={!nav ? 'hidden' : 'absolute bg-zinc-200 w-full px-8'}>
                <li className='border-b-2 border-zinc-300 w-full'><ScrollLink onClick={handleClose} to="hero" smooth={true} offset={0} duration={500}>Home</ScrollLink></li>
                <li className='border-b-2 border-zinc-300 w-full'><ScrollLink onClick={handleClose} to="about" smooth={true} offset={-150} duration={500}>About</ScrollLink></li>
                <li className='border-b-2 border-zinc-300 w-full'><ScrollLink onClick={handleClose} to="support" smooth={true} offset={-25} duration={500}>Support</ScrollLink></li>
                <li className='border-b-2 border-zinc-300 w-full'><ScrollLink onClick={handleClose} to="solutions" smooth={true} offset={-25} duration={500}>Solutions</ScrollLink></li>
                <li className='border-b-2 border-zinc-300 w-full'><ScrollLink onClick={handleClose} to="footer" smooth={true} offset={0} duration={500}>Contact</ScrollLink></li>
                <div className='flex flex-col my-4'>
                    <button className='px-8 py-3' onClick={toggleModal}>Free Estimate</button>
                </div>
            </ul>
        </div>
    );
};

export default Navbar;