import panel from '../assets/control-panel.png';

import React from 'react';
import { Link } from 'react-scroll';

const Hero = () => {
    return (
        <div name='hero' className='w-full pt-20 bg-zinc-200 flex flex-col justify-between'>
            <div className='grid md:grid-cols-2 p-1 max-w-[1240px] m-auto'>
                <div className='flex flex-col justify-center md:items-start w-full px-2 py-8'>
                    <h1 className='py-3 text-5xl md:text-7xl font-bold'>Packaging Machine Controls</h1>
                    <h2 className='text-2xl'>Serving Bottling, Packaging and Manufacturing Companies Globally</h2>
                    <button className='py-3 px-6 sm:w-[60%] my-4'><Link to="solutions" smooth={true} offset={-25} duration={500} className='block w-full h-full'>Get started</Link></button>
                </div>
                <div className='flex justify-center items-center px-2 py-8'>
                    <img className='rounded-xl shadow-2xl' src={panel} alt="packaging machine controls" />
                </div>
            </div>
        </div>
    );
};

export default Hero;