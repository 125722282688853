import React from 'react'

import { CheckIcon } from '@heroicons/react/outline';
import { Link } from 'react-router-dom';

const Solutions = () => {
    return (
        <div name='solutions' className='w-full text-black my-2'>
            <div className='w-full h-[800px] absolute mix-blend-overlay'></div>
            
            <div className='max-w-[1240px] mx-auto py-12'>
                <div className='text-center py-8 text-black'>
                    <h2 className='text-3xl uppercase'>Solutions</h2>
                    <h2 className='text-5xl font-bold py-8'>The right price for your controls</h2>
                    <h2 className='text-3xl'>We provide tailored solutions to meet your specific production needs, ensuring cost-effectiveness without compromising quality</h2>
                </div>

                <div className='grid md:grid-cols-1 lg:grid-cols-2'>
                    <div className='m-4 p-8 border rounded-xl shadow-2xl relative'>
                        <span className='uppercase px-3 py-1 bg-sky-200 text-sky-900 rounded-2xl sm:text-md md:text-2xl'>Gravity Filler Controls</span>
                        <div>
                            <p className='text-4xl font-bold py-4'><p className='text-sm font-bold mt-4'>Starting at</p>$6,500</p>
                        </div>
                        
                        <div className='text-2xl'>
                            <h2 className='flex items-center py-4 text-md md:text-xl'><CheckIcon className='w-8 h-8 mr-5 flex-shrink-0 text-green-600' />Up to 16 heads</h2>
                            <h2 className='flex items-center py-4 text-md md:text-xl'><CheckIcon className='w-8 h-8 mr-5 flex-shrink-0 text-green-600' />Line status monitoring</h2>
                            <h2 className='flex items-center py-4 text-md md:text-xl'><CheckIcon className='w-8 h-8 mr-5 flex-shrink-0 text-green-600' />Multiple indexing types</h2>
                            <h2 className='flex items-center py-4 text-md md:text-xl'><CheckIcon className='w-8 h-8 mr-5 flex-shrink-0 text-green-600' />Operator friendly</h2>
                            <h2 className='flex items-center py-4 text-md md:text-xl'><CheckIcon className='w-8 h-8 mr-5 flex-shrink-0 text-green-600' />Customize to your business needs</h2>
                            <Link to="/gravity-filler-controls" className='text-white border bg-sky-600 border-sky-600 hover:bg-transparent hover:text-sky-600 rounded-md block text-center py-4 my-4'>Learn more</Link>
                        </div>
                    </div>  

                    <div className='m-4 p-8 border rounded-xl shadow-2xl relative'>
                        <span className='uppercase px-3 py-1 bg-sky-200 text-sky-900 rounded-2xl sm:text-md md:text-2xl'>Pump Filler Controls</span>
                        <div>
                            <p className='text-4xl font-bold py-4'><p className='text-sm font-bold mt-4'>Starting at</p>$8,500</p>
                        </div>
                        
                        <div className='text-2xl'>
                            <h2 className='flex items-center py-4 text-md md:text-xl'><CheckIcon className='w-8 h-8 mr-5 flex-shrink-0 text-green-600' />Up to 12 heads</h2>
                            <h2 className='flex items-center py-4 text-md md:text-xl'><CheckIcon className='w-8 h-8 mr-5 flex-shrink-0 text-green-600' />Positive displacement or recirculating</h2>
                            <h2 className='flex items-center py-4 text-md md:text-xl'><CheckIcon className='w-8 h-8 mr-5 flex-shrink-0 text-green-600' />Pulse base fill level control</h2>
                            <h2 className='flex items-center py-4 text-md md:text-xl'><CheckIcon className='w-8 h-8 mr-5 flex-shrink-0 text-green-600' />Individual pump motor control</h2>
                            <h2 className='flex items-center py-4 text-md md:text-xl'><CheckIcon className='w-8 h-8 mr-5 flex-shrink-0 text-green-600' />Customize to your business needs</h2>
                            <Link to="/pump-filler-controls" className='text-white border bg-sky-600 border-sky-600 hover:bg-transparent hover:text-sky-600 rounded-md block text-center py-4 my-4'>Learn more</Link>
                        </div>
                    </div> 

                    <div className='m-4 p-8 border rounded-xl shadow-2xl relative'>
                        <span className='uppercase px-3 py-1 bg-sky-200 text-sky-900 rounded-2xl sm:text-md md:text-2xl'>Overflow Filler Controls</span>
                        <div>
                            <p className='text-4xl font-bold py-4'><p className='text-sm font-bold mt-4'>Starting at</p>$6,500</p>
                        </div>
                        
                        <div className='text-2xl'>
                            <h2 className='flex items-center py-4 text-md md:text-xl'><CheckIcon className='w-8 h-8 mr-5 flex-shrink-0 text-green-600' />Multiple fill speeds and times</h2>
                            <h2 className='flex items-center py-4 text-md md:text-xl'><CheckIcon className='w-8 h-8 mr-5 flex-shrink-0 text-green-600' />Anti-siphon check valve</h2>
                            <h2 className='flex items-center py-4 text-md md:text-xl'><CheckIcon className='w-8 h-8 mr-5 flex-shrink-0 text-green-600' />AC or DC fill pump control</h2>
                            <h2 className='flex items-center py-4 text-md md:text-xl'><CheckIcon className='w-8 h-8 mr-5 flex-shrink-0 text-green-600' />Analog controlled fill pump speeds</h2>
                            <h2 className='flex items-center py-4 text-md md:text-xl'><CheckIcon className='w-8 h-8 mr-5 flex-shrink-0 text-green-600' />Customize to your business needs</h2>
                            <Link to="/overflow-filler-controls" className='text-white border bg-sky-600 border-sky-600 hover:bg-transparent hover:text-sky-600 rounded-md block text-center py-4 my-4'>Learn more</Link>
                        </div>
                    </div> 

                    <div className='m-4 p-8 border rounded-xl shadow-2xl relative'>
                        <span className='uppercase px-3 py-1 bg-sky-200 text-sky-900 rounded-2xl sm:text-md md:text-2xl'>Piston Filler Controls</span>
                        <div>
                            <p className='text-4xl font-bold py-4'><p className='text-sm font-bold mt-4'>Starting at</p>$6,500</p>
                        </div>
                        
                        <div className='text-2xl'>
                            <h2 className='flex items-center py-4 text-md md:text-xl'><CheckIcon className='w-8 h-8 mr-5 flex-shrink-0 text-green-600' />Up to 12 individual fill pistons</h2>
                            <h2 className='flex items-center py-4 text-md md:text-xl'><CheckIcon className='w-8 h-8 mr-5 flex-shrink-0 text-green-600' />Fill piston full and empty status</h2>
                            <h2 className='flex items-center py-4 text-md md:text-xl'><CheckIcon className='w-8 h-8 mr-5 flex-shrink-0 text-green-600' />Individual or multi fill piston control</h2>
                            <h2 className='flex items-center py-4 text-md md:text-xl'><CheckIcon className='w-8 h-8 mr-5 flex-shrink-0 text-green-600' />Fill piston and fill head isolated control</h2>
                            <h2 className='flex items-center py-4 text-md md:text-xl'><CheckIcon className='w-8 h-8 mr-5 flex-shrink-0 text-green-600' />Customize to your business needs</h2>
                            <Link to="/piston-filler-controls" className='text-white border bg-sky-600 border-sky-600 hover:bg-transparent hover:text-sky-600 rounded-md block text-center py-4 my-4'>Learn more</Link>
                        </div>
                    </div> 

                    <div className='m-4 p-8 border rounded-xl shadow-2xl relative'>
                        <span className='uppercase px-3 py-1 bg-sky-200 text-sky-900 rounded-2xl sm:text-md md:text-2xl'>Spindle Capper Controls</span>
                        <div>
                            <p className='text-4xl font-bold py-4'><p className='text-sm font-bold mt-4'>Starting at</p>$3,800</p>
                        </div>
                        
                        <div className='text-2xl'>
                            <h2 className='flex items-center py-4 text-md md:text-xl'><CheckIcon className='w-8 h-8 mr-5 flex-shrink-0 text-green-600' />Up to 3 or 4 station spindle cappers</h2>
                            <h2 className='flex items-center py-4 text-md md:text-xl'><CheckIcon className='w-8 h-8 mr-5 flex-shrink-0 text-green-600' />First set of spindles reversing available</h2>
                            <h2 className='flex items-center py-4 text-md md:text-xl'><CheckIcon className='w-8 h-8 mr-5 flex-shrink-0 text-green-600' />Integrated full chute pause and elevator control</h2>
                            <h2 className='flex items-center py-4 text-md md:text-xl'><CheckIcon className='w-8 h-8 mr-5 flex-shrink-0 text-green-600' />AC or DC capper controls</h2>
                            <h2 className='flex items-center py-4 text-md md:text-xl'><CheckIcon className='w-8 h-8 mr-5 flex-shrink-0 text-green-600' />Customize to your business needs</h2>
                            <Link to="/spindle-capper-controls" className='text-white border bg-sky-600 border-sky-600 hover:bg-transparent hover:text-sky-600 rounded-md block text-center py-4 my-4'>Learn more</Link>
                        </div>
                    </div> 

                    <div className='m-4 p-8 border rounded-xl shadow-2xl relative'>
                        <span className='uppercase px-3 py-1 bg-sky-200 text-sky-900 rounded-2xl sm:text-md md:text-2xl'>Other Solutions</span>
                        
                        <div className='text-2xl xl:mt-11 mt-16'>
                            <h2 className='flex items-center py-4 text-md md:text-xl'><CheckIcon className='w-8 h-8 mr-5 flex-shrink-0 text-green-600' />Initial equipment startups</h2>
                            <h2 className='flex items-center py-4 text-md md:text-xl'><CheckIcon className='w-8 h-8 mr-5 flex-shrink-0 text-green-600' />Customized control panels</h2>
                            <h2 className='flex items-center py-4 text-md md:text-xl'><CheckIcon className='w-8 h-8 mr-5 flex-shrink-0 text-green-600' />System integrations</h2>
                            <h2 className='flex items-center py-4 text-md md:text-xl'><CheckIcon className='w-8 h-8 mr-5 flex-shrink-0 text-green-600' />Field service</h2>
                            <h2 className='flex items-center py-4 text-md md:text-xl'><CheckIcon className='w-8 h-8 mr-5 flex-shrink-0 text-green-600' />Control panel installation services</h2>
                            <h2 className='flex items-center py-4 text-md md:text-xl'><CheckIcon className='w-8 h-8 mr-5 flex-shrink-0 text-green-600' />Troubleshooting and diagnostic testing</h2>
                            <Link to="/other-solutions" className='text-white border bg-sky-600 border-sky-600 hover:bg-transparent hover:text-sky-600 rounded-md block text-center py-4 my-4'>Learn more</Link>
                        </div>
                    </div> 
 
                </div>
            </div>
        </div>
    )
};

export default Solutions;
