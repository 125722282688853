import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';

const LoginPage = () => {
  const { loginWithRedirect } = useAuth0();

  return (
    <div className='flex items-center justify-center min-h-screen bg-gray-100'>
      <div className='m-4 w-full max-w-md bg-gray-200 border border-black p-6 rounded-lg shadow-md'>
        <h1 className='mb-4'>Welcome!</h1>
        <button
          className='w-full focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center'
          onClick={() => loginWithRedirect()}
        >
          Log In
        </button>
      </div>
    </div>
  );
};

export default LoginPage;