import panel from '../assets/other-solutions.jpg';

import React, { useState } from 'react';
import Navbar2 from './Navbar2';
import Footer from './Footer';
import Estimate from './Estimate';

const OtherSolutions = () => {
    const[showModal, setShowModal] = useState(false);

    const toggleModal = () => setShowModal(!showModal);

    return (
        <>
            <Navbar2 toggleModal={toggleModal} />
            
            <div className='w-full pt-20 flex flex-col justify-between'>
                <div className='grid md:grid-cols-2 p-1 max-w-[1240px] m-auto'>
                    <div className='flex justify-center items-center px-8 py-8'>
                        <img className='h-auto sm:max-w-auto md:max-w-md rounded-xl shadow-2xl' src={panel} alt="packaging machine controls" />
                    </div>
                    <div className='flex flex-col justify-center md:items-start w-full px-2 py-8'>
                        <h1 className='py-3 text-5xl md:text-7xl font-bold'>Other Solutions</h1>
                        <h2 className='text-2xl'>Customizable controls for your business needs</h2>
                        <button className='py-3 px-6 sm:w-[60%] my-4' onClick={toggleModal}>Contact Us</button>
                    </div>
                </div>

                <div className='max-w-[1240px] mx-auto'>
                    <div className='text-2xl mt-10 text-center p-4 gap-4'>
                        <h3 className='leading-relaxed text-xl mb-10'>
                        J & J Industrial Service specializes in delivering custom control panel solutions tailored to meet the unique needs of the filling, capping, labeling, and conveyance production industries. Our expertise in control panel design and fabrication ensures seamless integration into existing and new systems, enhancing efficiency and reliability. With a focus on advanced technology, J & J Industrial Service provides innovative control panels that optimize the performance of pump fillers, gravity fillers, piston fillers, labeling machines and capping machines, driving productivity and precision in demanding packaging environments.
                        </h3>
                        <h3 className='leading-relaxed text-xl mb-10'>
                        We specialize in precise control panel installation, ensuring seamless integration into your packaging, bottling, or production line. Our team of expert technicians is trained to handle installations of any scale, guaranteeing minimal downtime and maximum efficiency for your operations. We pride ourselves on providing tailored installation services that meet the unique requirements of each client, ensuring optimal performance and reliability of your control systems.     
                        </h3>
                        <h3 className='leading-relaxed text-xl mb-10'>
                        Our control panel integration services are designed to seamlessly blend with your existing packaging and production systems, enhancing efficiency and reliability. We focus on creating a cohesive and intuitive interface, ensuring that all components work in harmony for optimal operational flow. Our skilled technicians are adept at integrating advanced technologies and custom solutions, ensuring a smooth and streamlined process that meets the specific needs of your industry.     
                        </h3>
                    </div>
                </div>
            </div>

            <Footer toggleModal={toggleModal} />

            <Estimate showModal={showModal} toggleModal={toggleModal} /> 
        </>
    );
};

export default OtherSolutions;