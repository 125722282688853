import support from '../assets/support.jpg';

import React from 'react';
import { ArrowSmRightIcon, PhoneIcon, SupportIcon } from '@heroicons/react/outline';
import { Link } from 'react-scroll';

const Support = () => {
    return (
        <div name='support' className='w-full mt-24'>
            <div className='w-full h-[700px] bg-gray-900/90 absolute'>
                <img className='w-full h-full object-cover mix-blend-overlay' src={support} alt="control panel support" />
            </div>
            <div className='max-w-[1240px] mx-auto text-white relative'>
                <div className='py-5 text-center'>
                    <h3 className='text-3xl pt-8 text-slate-300 uppercase'>Support</h3>
                    <p className='text-5xl font-bold py-6'>Finding the right team</p>
                    <h2 className='py-4 text-3xl text-slate-300'>
                        Custom control panel solutions designed for the specific needs of the filling, capping, labeling, and conveyance production industries
                    </h2>
                </div>

                <div className='grid grid-cols-1 lg:grid-cols-2 relative gap-x-8 gap-y-16 px-4 pt-12 sm:pt-20 text-black'>
                    <div className='bg-white rounded-xl shadow-2xl'>
                        <div className='p-8'>
                            <PhoneIcon className='w-16 p-4 bg-sky-600 text-white rounded-lg mt-[-4rem]'/>
                            <h3 className='font-bold text-2xl my-6'>Sales</h3>
                            <h2 className='text-gray-600 text-xl'>
                                Leveraging advanced technology, we provide control panels that enhance the performance of gravity fillers, pump fillers, overflow fillers, piston fillers, and capping machines, driving efficiency and precision in demanding environments.
                            </h2>
                        </div>
                        <div className='bg-slate-100 rounded-b-xl pl-8 py-4'>
                            <p className='flex items-center text-sky-600'><Link to="footer" smooth={true} offset={0} duration={500}>Contact Us<ArrowSmRightIcon className='w-5' /></Link></p>
                        </div>
                    </div>
                    <div className='bg-white rounded-xl shadow-2xl'>
                        <div className='p-8'>
                            <SupportIcon className='w-16 p-4 bg-sky-600 text-white rounded-lg mt-[-4rem]'/>
                            <h3 className='font-bold text-2xl my-6'>Technical Support</h3>
                            <h2 className='text-gray-600 text-xl'>
                                Our expert technicians ensure seamless installation or service of control panels, integrating them into your packaging or production line with minimal downtime and maximum efficiency.
                            </h2>
                        </div>
                        <div className='bg-slate-100 rounded-b-xl pl-8 py-4'>
                            <p className='flex items-center text-sky-600'><Link to="footer" smooth={true} offset={0} duration={500}>Contact Us<ArrowSmRightIcon className='w-5' /></Link></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Support;